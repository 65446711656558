import {FETCH_ERROR, FETCH_START, FETCH_SUCCESS, HIDE_MESSAGE, SHOW_MESSAGE, GET_REGIONS} from "../../constants/ActionTypes";
import { instance as axios } from 'util/Api';
import {message} from 'antd';

export const fetchStart = () => {
  return {
    type: FETCH_START
  }
};

export const fetchSuccess = () => {
  return {
    type: FETCH_SUCCESS
  }
};

export const fetchError = (error) => {
  return {
    type: FETCH_ERROR,
    payload: error
  }
};

export const showMessage = (message) => {
  return {
    type: SHOW_MESSAGE,
    payload: message
  }
};

export const hideMessage = () => {
  return {
    type: HIDE_MESSAGE
  }
};

export const getRegions = (type, parent) => {
  return (dispatch) => {
    axios.post('/PublicWeb/listregions', {
      "type": type,
      "parent_id": parent
    }).then(({data}) => {
      if (data.status_code === 0) {
        dispatch({type: GET_REGIONS, payload: {type: type, result: JSON.parse(data.body)}});
      } else {
        message.error(data.error_msg);
      }
    }).catch(function (error) {
      message.error("Интернет холболтоо шалгана уу!");
    });
  }
}





