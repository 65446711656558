import { Avatar, Col, message, Row, Tag, Button } from "antd";
import { ExportOutlined } from "@ant-design/icons";
import React, { useEffect, useState } from "react";
import { getUserById } from "../../util/Api";

const UserProfile = (props) => {
  const [user, setUser] = useState({});

  useEffect(() => {
    getUserById(props.user.id, onSuccess, onFailed);
  }, [props.user]);

  const onSuccess = (res) => {
    setUser(res);
  };

  const onFailed = (err) => {
    message.error(err);
  };

  let username = "Хэрэглэгч";

  if (props.user) {
    username = props.user.lastname + " " + props.user.firstname;
  }

  const ReturnUserType = (type) => {
    let text;
    switch (type) {
      case "Хөдөлмөр, Нийгмийн Хамгааллын Яам":
        text = <Tag color="#f50">{type}</Tag>;
        break;
      case "Гэр бүр хүүхэд залуучуудын газар":
        text = <Tag color="#2db7f5">{type}</Tag>;
        break;
      case "Нэг цэгийн үйлчилгээ & Түр хамгаалах байр":
        text = <Tag color="#87d068">{type}</Tag>;
        break;
      case "Системийн админ":
        text = <Tag color="magenta">{type}</Tag>;
        break;
      default:
        text = <Tag color="#108ee9">{type}</Tag>;
    }

    return text;
  };

  return (
    <div className="gx-flex-row gx-align-items-center gx-mb-4 gx-avatar-row">
      <a href="/profile">
        <Row>
          <Col span={6}>
            <Avatar
              src="https://via.placeholder.com/150x150"
              className="gx-size-40 gx-pointer gx-mr-3"
              alt=""
            />
          </Col>

          <Col span={16}>
            <span className="gx-avatar-name ant-menu-title-content">
              {username}
              <p style={{ fontSize: 12, marginTop: 10 }}>
                <Tag style={{ whiteSpace: "break-spaces" }} color="#f50">
                  {user.organization_name}
                </Tag>
              </p>
            </span>
            <Button
              icon={<ExportOutlined />}
              size="small"
              type="primary"
              onClick={() => {
                localStorage.clear();
                window.location.href = "/home";
              }}
            >
              Гарах
            </Button>
          </Col>
        </Row>
      </a>
    </div>
  );
};

export default UserProfile;
