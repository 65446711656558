import axios from "axios";

let instance = axios.create({
  // baseURL: `https://api.etuslamj.mn/api/v1/`,
  baseURL: `https://api.etuslamj.mn/api/v1/`,
});

const fileUploadURL = "https://api.etuslamj.mn/api/v1/Upload/upload/file";

instance.CancelToken = axios.CancelToken;
instance.isCancel = axios.isCancel;

instance.interceptors.request.use(
  (config) => {
    const token = JSON.parse(localStorage.getItem("token"));
    if (token) {
      config.headers = { Authorization: "Bearer " + token };
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

function request(url, data, successCallback, failCallback, method) {
  instance({
    method: method ? method : "POST",
    url: url,
    data: data,
  })
    .then((response) => {
      if (response.data.status_code === 0) {
        SuccessResponse(response.data, successCallback);
      } else {
        errorJob(response.data, failCallback);
      }
    })
    .catch((error) => {
      if (error.response) {
        if (error.response.status === 401) {
          localStorage.clear();
          window.location.href = "/";
        }
        failCallback(url + " - " + error);
      }
    });
}

function SuccessResponse(data, successCallback) {
  if (JSON.parse(data.body) !== null) {
    successCallback(JSON.parse(data.body));
  } else {
    successCallback([]);
  }
}

function errorJob(error, failCallback) {
  if (error.error_msg === "Authentication failed") {
    localStorage.clear();
    window.location = "/";
  }
  failCallback(error.error_msg);
}

function userSignIn(data, successCallback, failCallback) {
  instance
    .post(`/Auth/login`, data)
    .then((res) => {
      if (res.data.status_code === 0) {
        successCallback(res.data.body);
      } else {
        errorJob(res.data, failCallback);
      }
    })
    .catch((err) => {
      failCallback(err);
    });
  return "";
}

function userConfirm(token, successCallback, failCallback) {
  instance
    .post(`/Auth/confirm/${token}`)
    .then((res) => {
      if (res.data.status_code === 0) {
        successCallback(res.data.body);
      } else {
        errorJob(res.data, failCallback);
      }
    })
    .catch((err) => {
      failCallback(err);
    });
  return "";
}

function userSignUp(data, successCallback, failCallback) {
  instance
    .post("/Auth/register", data)
    .then((res) => {
      if (res.data.status_code === 0) {
        successCallback(res.data.body);
      } else {
        errorJob(res.data, failCallback);
      }
    })
    .catch((err) => {
      failCallback(err);
    });
  return "";
}

function getCrimeById(violation_id, successCallback, failCallback) {
  request(
    `/Violation/getviolation/${violation_id}`,
    {},
    successCallback,
    failCallback
  );
  return "";
}

function getChildByID(child_id, successCallback, failCallback) {
  request(
    `/ViolationChild/getviolationchild/${child_id}`,
    {},
    successCallback,
    failCallback
  );
  return "";
}

function getServiceByViolationId(violation_id, successCallback, failCallback) {
  request(
    `/Violation/getservice/${violation_id}`,
    {},
    successCallback,
    failCallback
  );
  return "";
}

function getServiceByChildViolationId(
  violation_id,
  successCallback,
  failCallback
) {
  request(
    `/ViolationChild/getservice/${violation_id}`,
    {},
    successCallback,
    failCallback
  );
  return "";
}

function getDashboardData(successCallback, failCallback) {
  request(`/PublicWeb/getstats`, {}, successCallback, failCallback);
  return "";
}

function getDashboardTableData(successCallback, failCallback) {
  request(`/PublicWeb/getaimagstats`, {}, successCallback, failCallback);
  return "";
}

function getChildDashboardTableData(successCallback, failCallback) {
  request(`/PublicWeb/getaimagstats/child`, {}, successCallback, failCallback);
  return "";
}

function getUsertype(successCallback, failCallback) {
  request(`/PublicWeb/getusertype`, {}, successCallback, failCallback);
  return "";
}

function getOrgtype(successCallback, failCallback) {
  request(`/PublicWeb/getorgtype`, {}, successCallback, failCallback);
  return "";
}

function getOrg(successCallback, failCallback) {
  request(`/PublicWeb/getorg`, {}, successCallback, failCallback);
  return "";
}

function getAffiliation(successCallback, failCallback) {
  request(`/PublicWeb/getaffiliation`, {}, successCallback, failCallback);
  return "";
}

function getDashboardChartData(filter, successCallback, failCallback) {
  request(
    `/PublicWeb/getvictimstats?type=${filter.type}&startdate=${filter.startdate}&enddate=${filter.enddate}`,
    {},
    successCallback,
    failCallback
  );
  return "";
}

function getRegions(data, successCallback, failCallback) {
  request(`/PublicWeb/listregions`, data, successCallback, failCallback);
  return "";
}

function getUserList(successCallback, failCallback) {
  request(`/User/list`, {}, successCallback, failCallback);
  return "";
}

function getForceUserOrgs(successCallback, failCallback) {
  request(
    `/User/getforceregister/org`,
    {},
    successCallback,
    failCallback,
    "GET"
  );
  return "";
}

function userForceRegister(data, successCallback, failCallback) {
  request(`/User/forceregister`, data, successCallback, failCallback);
  return "";
}

function setUserPassword(data, successCallback, failCallback) {
  request(`/User/forcechangepassword`, data, successCallback, failCallback);
  return "";
}

function setUserStatus(data, successCallback, failCallback) {
  request(`/User/approve`, data, successCallback, failCallback);
  return "";
}

function setUserChangeLead(data, successCallback, failCallback) {
  request(`/User/changelead`, data, successCallback, failCallback);
  return "";
}

function changeUserInfo(data, successCallback, failCallback) {
  request(`/User/edit`, data, successCallback, failCallback);
  return "";
}

function getSituation(id, data, successCallback, failCallback) {
  request(`/Violation/getsituation/${id}`, data, successCallback, failCallback);
  return "";
}

function exitViolation(data, successCallback, failCallback) {
  request(`/Violation/exit`, data, successCallback, failCallback);
  return "";
}

function getViolation(id, data, successCallback, failCallback) {
  request(`/Violation/getviolation/${id}`, data, successCallback, failCallback);
  return "";
}

function getSituationChild(
  violation_child_id,
  data,
  successCallback,
  failCallback
) {
  request(
    `/ViolationChild/getsituation/${violation_child_id}`,
    data,
    successCallback,
    failCallback
  );
  return "";
}

function addViolation(data, successCallback, failCallback) {
  request(`/Violation/addsituation`, data, successCallback, failCallback);
  return "";
}

function addChildViolation(data, successCallback, failCallback) {
  request(`/ViolationChild/addsituation`, data, successCallback, failCallback);
  return "";
}

function getUserById(user_id, successCallback, failCallback) {
  request(`/User/get/${user_id}`, {}, successCallback, failCallback);
  return "";
}

function addService(data, successCallback, failCallback) {
  request(`/Violation/addservice`, data, successCallback, failCallback);
  return "";
}

function addServiceChild(data, successCallback, failCallback) {
  request(`/ViolationChild/addservice`, data, successCallback, failCallback);
  return "";
}

function createCrimeOrg(data, successCallback, failCallback) {
  request(`/Violation/create`, data, successCallback, failCallback);
  return "";
}

function createChild(data, successCallback, failCallback) {
  request(`/ViolationChild/create`, data, successCallback, failCallback);
  return "";
}

function approveViolation(id, successCallback, failCallback) {
  request("/Violation/approve/" + id, {}, successCallback, failCallback);
  return "";
}

function getViolationList(successCallback, failCallback) {
  request(`/Violation/list`, {}, successCallback, failCallback);
  return "";
}

function getChildList(successCallback, failCallback) {
  request(`/ViolationChild/list`, {}, successCallback, failCallback);
  return "";
}

function getVictimChildByRd(rd, successCallback, failCallback) {
  request(
    `/Violation/getvictimchildinfo/` + rd,
    {},
    successCallback,
    failCallback
  );
  return "";
}

function getVictimByRd(rd, successCallback, failCallback) {
  request(`/Violation/getvictiminfo/` + rd, {}, successCallback, failCallback);
  return "";
}

function getViolationMayagtOne(rd, successCallback, failCallback) {
  request(
    `/ViolationMayagt/mayagtone/get/${rd}`,
    {},
    successCallback,
    failCallback
  );
  return "";
}

function createViolationMayagtOne(data, successCallback, failCallback) {
  request(
    "/ViolationMayagt/mayagtone/create",
    data,
    successCallback,
    failCallback
  );
  return "";
}

function deleteViolationMayagtOne(rd, successCallback, failCallback) {
  request(
    `/ViolationMayagt/mayagtone/delete/${rd}`,
    {},
    successCallback,
    failCallback
  );
  return "";
}

function downloadViolationMayagtOne(rd, successCallback, failCallback) {
  request(
    `/ViolationMayagt/mayagtone/download/${rd}`,
    {},
    successCallback,
    failCallback
  );
  return "";
}

function uploadViolationMayagtOne(data, successCallback, failCallback) {
  request(
    "/ViolationMayagt/mayagtone/upload",
    data,
    successCallback,
    failCallback
  );
  return "";
}

function getViolationMayagtTwo(rd, successCallback, failCallback) {
  request(
    `/ViolationMayagt/mayagttwo/get/${rd}`,
    {},
    successCallback,
    failCallback
  );
  return "";
}

function createViolationMayagtTwo(data, successCallback, failCallback) {
  request(
    "/ViolationMayagt/mayagttwo/create",
    data,
    successCallback,
    failCallback
  );
  return "";
}

function deleteViolationMayagtTwo(rd, successCallback, failCallback) {
  request(
    `/ViolationMayagt/mayagttwo/delete/${rd}`,
    {},
    successCallback,
    failCallback
  );
  return "";
}

function downloadViolationMayagtTwo(rd, successCallback, failCallback) {
  request(
    `/ViolationMayagt/mayagttwo/download/${rd}`,
    {},
    successCallback,
    failCallback
  );
  return "";
}

function uploadViolationMayagtTwo(data, successCallback, failCallback) {
  request(
    "/ViolationMayagt/mayagttwo/upload",
    data,
    successCallback,
    failCallback
  );
  return "";
}

function getViolationMayagtThree(rd, successCallback, failCallback) {
  request(
    `/ViolationMayagt/mayagtthree/get/${rd}`,
    {},
    successCallback,
    failCallback
  );
  return "";
}

function createViolationMayagtThree(data, successCallback, failCallback) {
  request(
    "/ViolationMayagt/mayagtthree/create",
    data,
    successCallback,
    failCallback
  );
  return "";
}

function deleteViolationMayagtThree(rd, successCallback, failCallback) {
  request(
    `/ViolationMayagt/mayagtthree/delete/${rd}`,
    {},
    successCallback,
    failCallback
  );
  return "";
}

function downloadViolationMayagtThree(rd, successCallback, failCallback) {
  request(
    `/ViolationMayagt/mayagtthree/download/${rd}`,
    {},
    successCallback,
    failCallback
  );
  return "";
}

function uploadViolationMayagtThree(data, successCallback, failCallback) {
  request(
    "/ViolationMayagt/mayagtthree/upload",
    data,
    successCallback,
    failCallback
  );
  return "";
}

function publicWebGetStatus(data, successCallback, failCallback) {
  request(
    `/PublicWeb/violations/getstats/${data}`,
    {},
    successCallback,
    failCallback
  );
  return "";
}

function publicWebGetStatusChild(data, successCallback, failCallback) {
  request(
    `/PublicWeb/violations/getstats/child/${data}`,
    {},
    successCallback,
    failCallback
  );
  return "";
}

function publicWebGetCountChild(successCallback, failCallback) {
  request(
    `/PublicWeb/violations/getcount/child`,
    {},
    successCallback,
    failCallback
  );
  return "";
}

function publicWebGetCount(successCallback, failCallback) {
  request(`/PublicWeb/violations/getcount`, {}, successCallback, failCallback);
  return "";
}

function publicWebVioListChild(data, successCallback, failCallback) {
  request(
    `/PublicWeb/violations/list/child/${data}`,
    {},
    successCallback,
    failCallback
  );
  return "";
}

function publicWebVioList(data, successCallback, failCallback) {
  request(
    `/PublicWeb/violations/list/${data}`,
    {},
    successCallback,
    failCallback
  );
  return "";
}

function publicwebHomeStat(successCallback, failCallback) {
  request(`/publicweb/home/stat`, {}, successCallback, failCallback);
  return "";
}

function publicgetOrg(successCallback, failCallback) {
  request(`/PublicWeb/getorgforreg`, {}, successCallback, failCallback, "GET");
  return "";
}

export {
  exitViolation,
  userForceRegister,
  getForceUserOrgs,
  publicgetOrg,
  userConfirm,
  publicwebHomeStat,
  getSituationChild,
  addServiceChild,
  getServiceByChildViolationId,
  addChildViolation,
  publicWebGetStatusChild,
  publicWebGetCountChild,
  publicWebVioListChild,
  fileUploadURL,
  createChild,
  getChildList,
  getChildByID,
  getChildDashboardTableData,
  instance,
  getCrimeById,
  getDashboardData,
  getDashboardChartData,
  getDashboardTableData,
  getOrgtype,
  getOrg,
  getUsertype,
  getAffiliation,
  getRegions,
  getServiceByViolationId,
  userSignIn,
  userSignUp,
  setUserChangeLead,
  getUserList,
  setUserPassword,
  setUserStatus,
  changeUserInfo,
  getSituation,
  getViolation,
  addViolation,
  getUserById,
  addService,
  createCrimeOrg,
  approveViolation,
  getViolationList,
  getVictimChildByRd,
  getVictimByRd,
  getViolationMayagtOne,
  createViolationMayagtOne,
  deleteViolationMayagtOne,
  downloadViolationMayagtOne,
  uploadViolationMayagtOne,
  getViolationMayagtTwo,
  createViolationMayagtTwo,
  deleteViolationMayagtTwo,
  downloadViolationMayagtTwo,
  uploadViolationMayagtTwo,
  getViolationMayagtThree,
  createViolationMayagtThree,
  deleteViolationMayagtThree,
  downloadViolationMayagtThree,
  uploadViolationMayagtThree,
  publicWebGetStatus,
  publicWebGetCount,
  publicWebVioList,
};
