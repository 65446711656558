import React, { useEffect } from "react";
import { Alert, Card, Typography } from "antd";
import { useSelector } from "react-redux";

const Title = Typography.Title;
const SignUpComplete = (props) => {
  const token = useSelector(({ auth }) => auth.token);

  useEffect(() => {
    if (token) {
      props.history.push("/");
    }
  }, [token, props.history]);

  return (
    <div className="gx-app-login-wrap">
      <Card
        className="gx-card"
        style={{
          maxWidth: "500px",
          margin: "0 auto",
        }}
      >
        <i
          className="icon icon-check-circle-o"
          style={{
            color: "rgb(55, 214, 122)",
            fontSize: "80px",
            display: "block",
            textAlign: "center",
          }}
        ></i>
        <Title
          level={4}
          style={{
            textAlign: "center",
          }}
        >
          Амжилттай бүртгэлээ
        </Title>
        <Typography.Paragraph
          style={{
            textAlign: "center",
            fontSize: "18px",
          }}
        >
          <Alert
            type="info"
            message="Таны бүртгэлийг хүлээн авлаа. Системийн админ таны оруулсан
            мэдээллийг шалгаж бүртгэлийг баталгаажуулах ба баталгаажуулсан тухай
            мэдээлэл таны имэйлээр очих болно."
          ></Alert>
        </Typography.Paragraph>

        <Typography.Paragraph
          style={{
            textAlign: "center",
            fontSize: "18px",
          }}
        >
          Эсвэл та{" "}
          <a
            target="_blank"
            href="https://www.facebook.com/groups/734363657464541/"
          >
            ЭНЭ FACEBOOK ГРУПП
          </a>{" "}
          болон
          <a
            target="_blank"
            href="https://www.facebook.com/ETuslamjmn-114559800408509/"
          >
            {" "}
            ЭНЭ ХУУДСААР
          </a>{" "}
          бидэнтэй холбогдоно уу.
        </Typography.Paragraph>
      </Card>
    </div>
  );
};

export default SignUpComplete;
