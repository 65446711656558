import React from "react";
import { Redirect, Route, Switch } from "react-router-dom";
import asyncComponent from "util/asyncComponent";

const Dashboard = ({ match }) => (
  <Switch>
    <Route
      exact
      path={`${match.url}/`}
      component={asyncComponent(() => import("./home/index"))}
    />
    <Route
      path={`${match.url}/huuli`}
      component={asyncComponent(() => import("./huuli/index"))}
    />

    <Route
      path={`${match.url}/sudalgaa`}
      component={asyncComponent(() => import("./sudalgaaStatistic/index"))}
    />
    <Route
      path={`${match.url}/prevent`}
      component={asyncComponent(() => import("./prevent/index"))}
    />
    <Route
      path={`${match.url}/security`}
      component={asyncComponent(() => import("./security/index"))}
    />
    <Route
      path={`${match.url}/covid`}
      component={asyncComponent(() => import("./covid/index"))}
    />
  </Switch>
);

export default Dashboard;
