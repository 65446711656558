import { GET_DASHBOARD_DATA } from '../../constants/ActionTypes'

const INIT_STATE = {
  dashboard: {},
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_DASHBOARD_DATA: {
      return {...state, dashboard: action.payload};
    }
    default:
      return state;
  }
}
