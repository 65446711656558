import { message, Spin, Button } from "antd";
import InfoView from "components/InfoView";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import IntlMessages from "util/IntlMessages";
import { Link } from "react-router-dom";
import { userConfirm } from "../util/Api";

const SignIn = (props) => {
  const [loading, setLoading] = useState(false);
  const [msg, setMsg] = useState("");

  const token = useSelector(({ auth }) => auth.token);

  const onSuccess = () => {
    setLoading(false);
    setMsg("Амжилттай баталгаажлаа. ");
    // localStorage.setItem("token", JSON.stringify(res.token));
    // dispatch({ type: USER_TOKEN_SET, payload: res.token });
    // props.history.push("/dashboard");
  };

  const onFailed = (err) => {
    setLoading(false);
    setMsg(err);
  };

  useEffect(() => {
    const { params } = props.match;
    userConfirm(params.id, onSuccess, onFailed);
  }, [token, props.history]);

  return (
    <div className="gx-app-login-wrap">
      <div className="gx-app-login-container">
        <div className="gx-app-login-main-content">
          <div className="gx-app-logo-content">
            <div className="gx-app-logo-content-bg">
              <img
                src={require("assets/images/login.jpg")}
                alt="Neature"
                style={{ objectFit: "cover" }}
              />
            </div>
            <div className="gx-app-logo-wid" style={{ margin: "auto" }}>
              <h1>etuslamj.mn</h1>
              <p>
                <IntlMessages id="app.userAuth.getAccount" />
              </p>
            </div>
          </div>
          {loading ? (
            <Spin />
          ) : (
            <div className="gx-app-login-content">
              {msg}

              <div style={{ marginBottom: "15px", marginTop: 40 }}>
                <Link to="/signin">
                  <IntlMessages id="app.userAuth.signIn" />
                </Link>{" "}
                <span>
                  <IntlMessages id="app.userAuth.or" />
                </span>{" "}
                <Link to="/signup">
                  <IntlMessages id="app.userAuth.signUp" />
                </Link>
              </div>
            </div>
          )}
          <InfoView />
        </div>
      </div>
      <div className="login-image">
        <img
          alt="example"
          style={{
            height: "49.49px",
            marginRight: "75px",
          }}
          src={require("assets/images/logo.png")}
        />
        <img
          alt="example"
          style={{
            height: "71.58px",
            marginRight: "75px",
          }}
          src={require("assets/images/logo2.png")}
        />
        <img
          alt="example"
          style={{
            height: "71.58px",
          }}
          src={require("assets/images/sdc.png")}
        />
      </div>
    </div>
  );
};

export default SignIn;
