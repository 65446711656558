import { Menu } from "antd";
import React from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import {
  NAV_STYLE_ABOVE_HEADER,
  NAV_STYLE_BELOW_HEADER,
  NAV_STYLE_DEFAULT_HORIZONTAL,
  NAV_STYLE_INSIDE_HEADER_HORIZONTAL,
} from "../../constants/ThemeSetting";

const SubMenu = Menu.SubMenu;

const HorizontalNav = () => {
  const navStyle = useSelector(({ settings }) => settings.navStyle);
  const pathname = useSelector(({ settings }) => settings.pathname);

  const getNavStyleSubMenuClass = (navStyle) => {
    switch (navStyle) {
      case NAV_STYLE_DEFAULT_HORIZONTAL:
        return "gx-menu-horizontal gx-submenu-popup-curve";
      case NAV_STYLE_INSIDE_HEADER_HORIZONTAL:
        return "gx-menu-horizontal gx-submenu-popup-curve gx-inside-submenu-popup-curve";
      case NAV_STYLE_BELOW_HEADER:
        return "gx-menu-horizontal gx-submenu-popup-curve gx-below-submenu-popup-curve";
      case NAV_STYLE_ABOVE_HEADER:
        return "gx-menu-horizontal gx-submenu-popup-curve gx-above-submenu-popup-curve";
      default:
        return "gx-menu-horizontal";
    }
  };

  const selectedKeys = pathname.substr(1);
  const defaultOpenKeys = selectedKeys.split("/")[1];
  return (
    <Menu
      defaultOpenKeys={[defaultOpenKeys]}
      selectedKeys={[selectedKeys]}
      mode="horizontal"
      disabledOverflow
    >
      <SubMenu
        className={getNavStyleSubMenuClass(navStyle)}
        key="huuli_togtoomj"
        title={
          <>
            <img
              width={30}
              src="https://file.etuslamj.mn/public-file/logo/Law-01.svg"
              style={{ marginRight: 5 }}
            />
            Хууль тогтоомж
          </>
        }
      >
        <Menu.Item key="1">
          <Link to="/home/huuli/gerbul">
            Гэр бүлийн хүчирхийлэлтэй тэмцэх тухай хууль
          </Link>
        </Menu.Item>
        <Menu.Item>
          <Link to="/home/huuli/holbogdohhuuli">Холбогдох хууль</Link>
        </Menu.Item>
        <Menu.Item>
          <Link to="/home/huuli/juram">Журам, дүрэм, стандарт</Link>
        </Menu.Item>
      </SubMenu>

      <SubMenu
        className={getNavStyleSubMenuClass(navStyle)}
        key="sudalgaa_statistik"
        title={
          <>
            <img
              width={30}
              src="https://file.etuslamj.mn/public-file/logo/Statis-01.svg"
              style={{ marginRight: 5 }}
            />
            Судалгаа, статистик
          </>
        }
      >
        <Menu.Item key="2">
          <Link to="/home/sudalgaa/statistic">Судалгаа</Link>
        </Menu.Item>
        <Menu.Item key="3">
          <Link to="/home/sudalgaa/info">Тоо мэдээ, Инфографик</Link>
        </Menu.Item>
      </SubMenu>

      <SubMenu
        className={getNavStyleSubMenuClass(navStyle)}
        key="uridchilsan_sergiileh"
        title={
          <>
            <img
              width={30}
              src="https://file.etuslamj.mn/public-file/logo/insu-01.svg"
              style={{ marginRight: 5 }}
            />
            Урьдчилан сэргийлэх
          </>
        }
      >
        <Menu.Item key="4">
          <Link to="/home/prevent/manual">Гарын авлага</Link>
        </Menu.Item>
        <Menu.Item key="5">
          <Link to="/home/prevent/wrong">
            Гэр бүлийн хүчирхийллийн талаарх алдаатай ойлголтууд
          </Link>
        </Menu.Item>
        <Menu.Item key="6">
          <Link to="/home/prevent/video">Видео</Link>
        </Menu.Item>
        <Menu.Item key="7">
          <Link to="/home/prevent/advice">Цахим зөвлөмж</Link>
        </Menu.Item>
        <Menu.Item key="8">
          <Link to="/home/prevent/poster">Зурагт хуудас</Link>
        </Menu.Item>
        <Menu.Item key="9">
          <Link to="/home/prevent/test">Сэтгэлзүйн сорил</Link>
        </Menu.Item>
        <Menu.Item key="10">
          <Link to="/home/prevent/ygodoo">Яг одоо аян</Link>
        </Menu.Item>
      </SubMenu>

      <SubMenu
        className={getNavStyleSubMenuClass(navStyle)}
        key="hamgaalliin_uilchilgee"
        title={
          <>
            <img
              width={30}
              src="https://file.etuslamj.mn/public-file/logo/Welfare-01.svg"
              style={{ marginRight: 5 }}
            />
            Хамгааллын үйлчилгээ
          </>
        }
      >
        <Menu.Item key="11">
          <Link to="/home/security/legal-advice">
            Хууль зүйн цахим зөвлөгөө
          </Link>
        </Menu.Item>
        <Menu.Item key="12">
          <Link to="/home/security/mental-advice">
            Сэтгэл зүйн цахим зөвлөгөө
          </Link>
        </Menu.Item>
        <Menu.Item key="13">
          <Link to="/home/security/security-service">Хамгаалах үйлчилгээ</Link>
        </Menu.Item>
        <Menu.Item key="14">
          <Link to="/home/security/assistance-organizations">
            Туслалцаа үзүүлэх байгууллагууд
          </Link>
        </Menu.Item>
        <Menu.Item key="15">
          <Link to="/home/security/talk-together">Хамтдаа ярилцъя</Link>
        </Menu.Item>
      </SubMenu>

      <SubMenu
        className={getNavStyleSubMenuClass(navStyle)}
        key="covid_19"
        title={
          <>
            <img
              width={30}
              src="https://file.etuslamj.mn/public-file/logo/Covid-19-01.svg"
              style={{ marginRight: 5 }}
            />
            Ковид 19
          </>
        }
      >
        <Menu.Item key="16">
          <Link to="/home/covid">Ковид 19</Link>
        </Menu.Item>
      </SubMenu>

      <Menu.Item key="17">
        <Link style={{ color: "white", fontSize: 14 }} to="/dashboard">
          Бүртгэлийн систем
        </Link>
      </Menu.Item>
    </Menu>
  );
};

HorizontalNav.propTypes = {};

export default HorizontalNav;
