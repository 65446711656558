import React, { useState } from "react";
import { Button, Dropdown, Layout, Menu, message, Popover } from "antd";
import { connect, useDispatch, useSelector } from "react-redux";
import CustomScrollbars from "util/CustomScrollbars";
import languageData from "../languageData";
import SearchBox from "components/SearchBox";
import UserInfo from "components/UserInfo";
import AppNotification from "components/AppNotification";
import MailNotification from "components/MailNotification";
import HorizontalNav from "../HorizontalNav";
import { Link } from "react-router-dom";
import {
  switchLanguage,
  toggleCollapsedSideNav,
} from "../../../appRedux/actions/Setting";
import IntlMessages from "../../../util/IntlMessages";
import DownOutlined from "@ant-design/icons/lib/icons/DownOutlined";

const { Header } = Layout;

const menu = (
  <Menu onClick={handleMenuClick}>
    <Menu.Item key="1">Products</Menu.Item>
    <Menu.Item key="2">Apps</Menu.Item>
    <Menu.Item key="3">Blogs</Menu.Item>
  </Menu>
);

function handleMenuClick(e) {
  message.info("Click on menu item.");
}

const InsideHeader = () => {
  const dispatch = useDispatch();
  const navCollapsed = useSelector(({ settings }) => settings.navCollapsed);

  return (
    <div className="gx-header-horizontal gx-header-horizontal-dark gx-inside-header-horizontal">
      <Header className="gx-header-horizontal-main">
        <div className="gx-container">
          <div className="gx-header-horizontal-main-flex">
            <div className="gx-d-block gx-d-lg-none gx-linebar gx-mr-xs-3 6e">
              <i
                className="gx-icon-btn icon icon-menu"
                onClick={() => {
                  dispatch(toggleCollapsedSideNav(!navCollapsed));
                }}
              />
            </div>
            <Link
              to="/"
              className="gx-d-block gx-d-lg-none gx-pointer gx-mr-xs-3 gx-pt-xs-1 gx-w-logo"
            >
              <img alt="" src={require("assets/images/w-logo.png")} />
            </Link>
            <Link
              to="/home"
              className="gx-d-none gx-d-lg-block gx-pointer gx-mr-xs-5 gx-logo"
            >
              <div>
                <img
                  alt=""
                  width={50}
                  src={require("assets/images/logo-etuslamj.png")}
                />{" "}
                <span
                  style={{
                    fontSize: 21,
                    color: "white",
                    fontWeight: "bold",
                    marginLeft: 5,
                  }}
                >
                  ETuslamj
                </span>
              </div>
            </Link>

            <div className="gx-ml-auto gx-header-horizontal-nav gx-header-horizontal-nav-curve gx-d-none gx-d-lg-block">
              <HorizontalNav />
            </div>
          </div>

          <div
            style={{
              fontSize: "25px",
              fontWeight: "bold",
              color: "white",
              textAlign: "center",
              margin: "21px 0px",
            }}
          >
            Хамтдаа хамгаалъя
          </div>
        </div>
      </Header>
    </div>
  );
};

const mapStateToProps = ({ settings }) => {
  const { locale, navCollapsed } = settings;
  return { locale, navCollapsed };
};
export default connect(mapStateToProps, {
  toggleCollapsedSideNav,
  switchLanguage,
})(InsideHeader);
