import React from "react";
import { Spin } from 'antd'
import loader from "../../assets/images/loader.svg";

const CircularProgress = ({ className }) => (
  <div className={`loader ${className}`}>
    <div
      style={{
        width: "100%",
        height: "80vh",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <Spin />
      {/* <img src={loader} alt="loader" style={{ width: 100 }} /> */}
    </div>
  </div>
);
export default CircularProgress;
