import {FETCH_ERROR, FETCH_START, FETCH_SUCCESS, HIDE_MESSAGE, SHOW_MESSAGE, GET_REGIONS} from '../../constants/ActionTypes'

const INIT_STATE = {
  error: "",
  loading: false,
  message: '',
  aimag: [],
  sum: [],
  bag: [],
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case FETCH_START: {
      return {...state, error: '', message: '', loading: true};
    }
    case FETCH_SUCCESS: {
      return {...state, error: '', message: '', loading: false};
    }
    case SHOW_MESSAGE: {
      return {...state, error: '', message: action.payload, loading: false};
    }
    case FETCH_ERROR: {
      return {...state, loading: false, error: action.payload, message: ''};
    }
    case HIDE_MESSAGE: {
      return {...state, loading: false, error: '', message: ''};
    }
    case GET_REGIONS: {
      if(action.payload.type === 1){
        return {
          ...state,
          sum: [],
          bag: [],
          aimag: action.payload.result
        }
      } else if (action.payload.type === 2) {
        return {
          ...state,
          bag: [],
          sum:  action.payload.result
        }
      } else if (action.payload.type === 3){
        return  {
          ...state,
          bag: action.payload.result,
        }
      }
      return state;
    }
    default:
      return state;
  }
}
