import {
  Button,
  Form,
  Input,
  InputNumber,
  message,
  TreeSelect,
  Typography,
} from "antd";
import InfoView from "components/InfoView";
import React, { useEffect, useRef, useState } from "react";
import { Link } from "react-router-dom";
import IntlMessages from "util/IntlMessages";
import { publicgetOrg, userSignUp } from "../util/Api";

const Title = Typography.Title;
const FormItem = Form.Item;
const { TreeNode } = TreeSelect;

const formItemLayout = {
  labelCol: {
    xs: { span: 24 },
    sm: { span: 12 },
  },
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 16 },
  },
};

const SignUp = (props) => {
  const isMountedRef = useRef(null);
  const [regOrgs, setRegOrgs] = useState([]);

  const [form] = Form.useForm();

  const onFinish = (values) => {
    userSignUp(values, onSuccess, onFailed);
  };

  const onSuccess = () => {
    if (isMountedRef.current) {
      props.history.push("/signup-complete");
    }
  };

  const onFailed = (err) => {
    message.error(err);
  };

  useEffect(() => {
    isMountedRef.current = true;
    publicgetOrg(onSucessOrgs, onFailed);
    return () => (isMountedRef.current = false);
  }, []);

  const onSucessOrgs = (response) => {
    setRegOrgs(response);
  };

  return (
    <div
      className="gx-app-login-wrap"
      style={{
        display: "block",
      }}
    >
      <div
        className="gx-app-login-container"
        style={{
          margin: "20px auto",
          maxWidth: "1200px",
          width: "100%",
        }}
      >
        <div className="gx-app-login-main-content">
          <div className="gx-app-logo-content">
            <div className="gx-app-logo-content-bg">
              <img
                src={require("assets/images/login.jpg")}
                style={{ objectFit: "cover" }}
                alt="Neature"
              />
            </div>
            <div className="gx-app-logo-wid" style={{ margin: "auto" }}>
              <h1>etuslamj.mn</h1>
              <p>БҮРТГҮҮЛЭХ</p>
              <p>
                <IntlMessages id="app.userAuth.getAccount" />
              </p>
            </div>
          </div>

          <div className="gx-app-login-content">
            <Form
              {...formItemLayout}
              initialValues={{ remember: true }}
              name="singup"
              form={form}
              onFinish={onFinish}
              className=""
            >
              <Title level={4}>Бүртгэлийн мэдээлэл</Title>
              <FormItem
                name="email"
                label="И-мэйл хаяг"
                rules={[
                  {
                    required: true,
                    type: "email",
                    message: "E-mail хаягыг оруулах шаардлагатай",
                  },
                ]}
              >
                <Input />
              </FormItem>
              <FormItem
                shouldUpdate
                name="password"
                label="Нууц үг"
                rules={[{ required: true, message: "Нууц үгээ оруулна уу" }]}
              >
                <Input type="password" placeholder="Нууц үг" />
              </FormItem>
              <FormItem
                name="confirm"
                dependencies={["password"]}
                hasFeedback
                label="Баталгаажуулах"
                rules={[
                  {
                    required: true,
                    message: "Нууц үгээ баталгаажуулан уу!",
                  },
                  ({ getFieldValue }) => ({
                    validator(_, value) {
                      if (!value || getFieldValue("password") === value) {
                        return Promise.resolve();
                      }
                      return Promise.reject(
                        "Таны оруулсан 2 нууц үг хоорондоо таарахгүй байна!"
                      );
                    },
                  }),
                ]}
              >
                <Input type="password" placeholder="Нууц үг давтах" />
              </FormItem>

              <Title level={4}>Дэлгэрэнгүй мэдээлэл</Title>

              <Form.Item
                name="org"
                label="Байгууллага"
                rules={[
                  {
                    required: true,
                    message: "Байгууллага сонгоно уу!",
                  },
                ]}
              >
                <TreeSelect
                  showSearch
                  style={{ width: "100%" }}
                  // value={value}
                  dropdownStyle={{ maxHeight: 400, overflow: "auto" }}
                  placeholder="Байгууллага сонгох"
                  allowClear
                  treeDefaultExpandAll
                  // onChange={onChange}
                >
                  {regOrgs.map((i) => {
                    return (
                      <TreeNode
                        value={"0" + i.id}
                        selectable={false}
                        title={i.name}
                      >
                        {i.children.map((j) => {
                          return <TreeNode value={j.id} title={j.name} />;
                        })}
                      </TreeNode>
                    );
                  })}
                  {/* < */}
                </TreeSelect>
              </Form.Item>

              <FormItem
                name="profession"
                label="Эзэмшсэн мэргэжил"
                rules={[
                  {
                    required: true,
                    message: "Эзэмшсэн мэргэжил талбарыг оруулан уу!",
                  },
                ]}
              >
                <Input />
              </FormItem>

              <FormItem
                name="worker_position"
                label="Албан тушаал"
                rules={[
                  {
                    required: true,
                    message: "Албан тушаал талбарыг оруулан уу!",
                  },
                ]}
              >
                <Input />
              </FormItem>

              <Title level={4}>Хувийн мэдээлэл</Title>
              <FormItem
                name="lastname"
                label="Овог"
                rules={[
                  { required: true, message: "Овог талбарыг оруулан уу!" },
                ]}
              >
                <Input type="text" />
              </FormItem>
              <FormItem
                name="firstname"
                label="Нэр"
                rules={[
                  { required: true, message: "Нэр талбарыг оруулан уу!" },
                ]}
              >
                <Input type="text" />
              </FormItem>
              <FormItem
                name="tel"
                label="Утасны дугаар"
                tooltip="Бүртгэлийг баталгаажуулахын өмнө тан луу залгаж мэдээллийг шалгах тул үнэн зөв утасны дугаар оруулна уу"
                rules={[
                  {
                    required: true,
                    message: "Утасны дугаар талбарыг оруулан уу!",
                  },
                ]}
              >
                <InputNumber style={{ width: "100%" }} />
              </FormItem>
              <div style={{ float: "right" }}>
                <Button type="primary" className="gx-mb-0" htmlType="submit">
                  <IntlMessages id="app.userAuth.signUp" />
                </Button>
                <span>
                  <IntlMessages id="app.userAuth.or" />
                </span>{" "}
                <Link to="/signin">
                  <IntlMessages id="app.userAuth.signIn" />
                </Link>
              </div>
            </Form>
          </div>
          <InfoView />
        </div>
      </div>
      <div className="login-image">
        <img
          alt="example"
          style={{
            height: "49.49px",
            marginRight: "75px",
          }}
          src={require("assets/images/logo.png")}
        />
        <img
          alt="example"
          style={{
            height: "71.58px",
          }}
          src={require("assets/images/logo2.png")}
        />
      </div>
    </div>
  );
};

export default SignUp;
