import {
  FETCH_START,
  FETCH_SUCCESS,
  INIT_URL,
  SIGNOUT_USER_SUCCESS,
  USER_DATA,
  SIGNUP_USER_SUCCESS,
  USER_TOKEN_SET,
} from "../../constants/ActionTypes";
import { instance as axios } from "util/Api";
import { message } from "antd";

export const setInitUrl = (url) => {
  return {
    type: INIT_URL,
    payload: url,
  };
};

export const userSignUp = (data) => {
  return (dispatch) => {
    dispatch({ type: FETCH_START });
    axios
      .post("/Auth/register", data)
      .then(({ data }) => {
        if (data.status_code === 0) {
          dispatch({ type: FETCH_SUCCESS });
          dispatch({ type: SIGNUP_USER_SUCCESS });
          message.success("Амжилттай бүртгүүллээ!");
        }
      })
      .catch(function (error) {
        message.error("Интертэн холболтоо шалгана уу");
      });
  };
};

export const userSignIn = ({ email, password }) => {
  return (dispatch) => {
    axios
      .post("/Auth/login", {
        email: email,
        password: password,
      })
      .then(({ data }) => {
        if (data.body && data.body.token) {
          localStorage.setItem("token", JSON.stringify(data.body.token));
          dispatch({ type: FETCH_SUCCESS });
          dispatch({ type: USER_TOKEN_SET, payload: data.body.token });
        }
      })
      .catch(function (error) {
        // message.error('Интертэн холболтоо шалгана уу');
      });
  };
};

export const getUser = () => {
  return (dispatch) => {
    axios
      .post("auth/me")
      .then(({ data }) => {
        if (data.result) {
          dispatch({ type: FETCH_SUCCESS });
          dispatch({ type: USER_DATA, payload: data.user });
        }
      })
      .catch(function (error) {
        message.error("Интертэн холболтоо шалгана уу");
      });
  };
};

export const userSignOut = () => {
  return (dispatch) => {
    localStorage.removeItem("token");
    dispatch({ type: SIGNOUT_USER_SUCCESS });

    // dispatch({type: FETCH_START});

    // axios.post('auth/logout').then(({data}) => {
    //   if (data.result) {
    //     localStorage.removeItem("token");
    //     dispatch({type: FETCH_SUCCESS});
    //     dispatch({type: SIGNOUT_USER_SUCCESS});
    //   } else {
    //     dispatch({type: FETCH_ERROR, payload: data.error});
    //   }
    // }).catch(function (error) {
    //   dispatch({type: FETCH_ERROR, payload: error.message});
    // });
  };
};
