import React from "react";
import { useSelector } from "react-redux";
import { Menu } from "antd";
import { Link } from "react-router-dom";

import CustomScrollbars from "util/CustomScrollbars";
import SidebarLogo from "./SidebarLogo";
import UserProfile from "./UserProfile";
import {
  NAV_STYLE_NO_HEADER_EXPANDED_SIDEBAR,
  NAV_STYLE_NO_HEADER_MINI_SIDEBAR,
  THEME_TYPE_LITE,
} from "../../constants/ThemeSetting";
import IntlMessages from "../../util/IntlMessages";
import SubMenu from "antd/lib/menu/SubMenu";

const SidebarContent = () => {
  let { navStyle, themeType, pathname, auth } = useSelector(
    ({ settings, auth }) => ({ ...settings, auth })
  );

  const getNoHeaderClass = (navStyle) => {
    if (
      navStyle === NAV_STYLE_NO_HEADER_MINI_SIDEBAR ||
      navStyle === NAV_STYLE_NO_HEADER_EXPANDED_SIDEBAR
    ) {
      return "gx-no-header-notifications";
    }
    return "";
  };

  const selectedKeys = pathname.substr(1);
  const defaultOpenKeys = selectedKeys.split("/")[1];

  if (!auth.currentUser) return <></>;

  const type = auth.currentUser.type;

  let userMenu = null;
  if (type === "99" || type === "1") {
    userMenu = (
      <Menu.Item key="users">
        <Link to="/users">
          <i className="icon icon-user" />
          <span>
            <IntlMessages id="sidebar.users" />
          </span>
        </Link>
      </Menu.Item>
    );
  }

  let crimeMenu = null;
  if (type !== "5") {
    crimeMenu = (
      <SubMenu
        style={{
          whiteSpace: "break-spaces",
          lineHeight: "20px",
        }}
        title={
          <span>
            <i className="icon icon-auth-screen" />
            <span>Насанд хүрэгч</span>
          </span>
        }
        key="person"
      >
        <Menu.Item key="crime">
          <Link to="/crime-main">
            <span>Бүртгэл</span>
          </Link>
        </Menu.Item>
        <Menu.Item
          key="nohtsolbaidal"
          style={{
            whiteSpace: "break-spaces",
            lineHeight: "20px",
          }}
        >
          <Link to="/nohtsolbaidal">
            <span>Нөхцөл байдлын үнэлгээ</span>
          </Link>
        </Menu.Item>
        <Menu.Item
          key="uilchilgee"
          style={{
            whiteSpace: "break-spaces",
            lineHeight: "20px",
          }}
        >
          <Link to="/service">
            <span>Үзүүлсэн үйлчилгээ</span>
          </Link>
        </Menu.Item>
      </SubMenu>
    );
  }
  return (
    <>
      <SidebarLogo />

      <div
        className="gx-sidebar-content"
        style={{ height: "100%", overflowY: "scroll" }}
      >
        <div
          className={`gx-sidebar-notifications ${getNoHeaderClass(navStyle)}`}
        >
          <UserProfile user={auth.currentUser} />
        </div>
        <CustomScrollbars className="gx-layout-sider-scrollbar">
          <Menu
            defaultOpenKeys={[defaultOpenKeys]}
            selectedKeys={[selectedKeys]}
            theme={themeType === THEME_TYPE_LITE ? "lite" : "dark"}
            mode="inline"
          >
            <Menu.Item key="dashboard">
              <Link to="/dashboard">
                <i className="icon icon-home" />
                <span>
                  <IntlMessages id="sidebar.dashboard" />
                </span>
              </Link>
            </Menu.Item>
            {userMenu}
            {crimeMenu}
            <SubMenu
              title={
                <span>
                  <i className="icon icon-auth-screen" />
                  <span>Хүүхэд</span>
                </span>
              }
              key="child"
            >
              <Menu.Item
                key="child-crime"
                style={{
                  whiteSpace: "break-spaces",
                  lineHeight: "20px",
                }}
              >
                <Link to="/child-crime-main">
                  <span>Бүртгэл</span>
                </Link>
              </Menu.Item>
              <Menu.Item
                key="child-nohtsolbaidal"
                style={{
                  whiteSpace: "break-spaces",
                  lineHeight: "20px",
                }}
              >
                <Link to="/child-nohtsolbaidal">
                  <span>Нөхцөл байдлын үнэлгээ</span>
                </Link>
              </Menu.Item>
              <Menu.Item
                key="child-uilchilgee"
                style={{
                  whiteSpace: "break-spaces",
                  lineHeight: "20px",
                }}
              >
                <Link to="/child-service">
                  <span>Үзүүлсэн үйлчилгээ</span>
                </Link>
              </Menu.Item>
            </SubMenu>
            {type === "99" || type === "1" ? (
              <SubMenu
                title={
                  <span>
                    <i className="icon icon-dasbhoard" />
                    <span>Тайлан</span>
                  </span>
                }
                key="dash-report"
              >
                <Menu.Item key="person-report">
                  <Link to="/report">
                    <span>Насанд хүрэгч</span>
                  </Link>
                </Menu.Item>
                <Menu.Item key="child-report">
                  <Link to="/child-report">
                    <span>Хүүхэд</span>
                  </Link>
                </Menu.Item>
              </SubMenu>
            ) : null}
          </Menu>
        </CustomScrollbars>
      </div>
    </>
  );
};

SidebarContent.propTypes = {};

export default SidebarContent;
