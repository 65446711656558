import React from "react";
import { useSelector } from "react-redux";
import { Menu } from "antd";
import { Link } from "react-router-dom";

import CustomScrollbars from "util/CustomScrollbars";
import SidebarLogo from "./SidebarLogo";
import UserProfile from "./UserProfile";
import {
  NAV_STYLE_NO_HEADER_EXPANDED_SIDEBAR,
  NAV_STYLE_NO_HEADER_MINI_SIDEBAR,
  THEME_TYPE_LITE,
} from "../../constants/ThemeSetting";
import IntlMessages from "../../util/IntlMessages";

const SidebarContent = () => {
  let { navStyle, themeType, pathname, auth } = useSelector(
    ({ settings, auth }) => ({ ...settings, auth })
  );

  const getNoHeaderClass = (navStyle) => {
    if (
      navStyle === NAV_STYLE_NO_HEADER_MINI_SIDEBAR ||
      navStyle === NAV_STYLE_NO_HEADER_EXPANDED_SIDEBAR
    ) {
      return "gx-no-header-notifications";
    }
    return "";
  };

  const selectedKeys = pathname.substr(1);
  const defaultOpenKeys = selectedKeys.split("/")[1];

  if (!auth.currentUser) return <></>;

  const type = auth.currentUser.type;
  const isLead = auth.currentUser.is_lead;
  // const isLead = auth.currentUser.is_lead;

  let userMenu = null;
  if (type === "99" || type === "2" || type === "1") {
    userMenu = (
      <Menu.Item key="users">
        <Link to="/users">
          <i className="icon icon-user" />
          <span>
            <IntlMessages id="sidebar.users" />
          </span>
        </Link>
      </Menu.Item>
    );
  }

  let crimeMenu = null;
  if (type !== "5") {
    crimeMenu = (
      <Menu.Item key="crime">
        <Link to="/crime-main">
          <i className="icon icon-widgets" />
          <span>
            <IntlMessages id="sidebar.crime" />
          </span>
        </Link>
      </Menu.Item>
    );
  }
  return (
    <>
      <SidebarLogo />

      <div className="gx-sidebar-content">
        <div
          className={`gx-sidebar-notifications ${getNoHeaderClass(navStyle)}`}
        >
          <UserProfile user={auth.currentUser} />
        </div>
        <CustomScrollbars className="gx-layout-sider-scrollbar">
          <Menu
            defaultOpenKeys={[defaultOpenKeys]}
            selectedKeys={[selectedKeys]}
            theme={themeType === THEME_TYPE_LITE ? "lite" : "dark"}
            mode="inline"
          >
            <Menu.Item key="dashboard">
              <Link to="/dashboard">
                <i className="icon icon-home" />
                <span>
                  <IntlMessages id="sidebar.dashboard" />
                </span>
              </Link>
            </Menu.Item>
            {userMenu}
            {crimeMenu}
            <Menu.Item key="nohtsolbaidal">
              <Link to="/nohtsolbaidal">
                <i className="icon icon-editor" />
                <span>Нөхцөл байдлын үнэлгээ</span>
              </Link>
            </Menu.Item>
            <Menu.Item key="uilchilgee">
              <Link to="/service">
                <i className="icon icon-ckeditor" />
                <span>Үйлчилгээ</span>
              </Link>
            </Menu.Item>
            <Menu.Item key="report">
              <Link to="/report">
                <i className="icon icon-chart" />
                <span>Тайлан</span>
              </Link>
            </Menu.Item>
          </Menu>
        </CustomScrollbars>
      </div>
    </>
  );
};

SidebarContent.propTypes = {};

export default SidebarContent;
