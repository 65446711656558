import React from "react";
import { Switch, Route } from "react-router-dom";
import { Layout, Row, Col, Form, Input, Table, Button } from "antd";
import Widgets from "./Widgets";
import Metrics from "./Metrics";
import Dashboard from "./dashboard";
import Layouts from "./Layouts";

import Slider from "react-slick";
import asyncComponent from "util/asyncComponent";
import { useSelector } from "react-redux";

import {
  NAV_STYLE_DRAWER,
  NAV_STYLE_FIXED,
  NAV_STYLE_MINI_SIDEBAR,
  NAV_STYLE_NO_HEADER_EXPANDED_SIDEBAR,
  NAV_STYLE_NO_HEADER_MINI_SIDEBAR,
  TAB_SIZE,
} from "../../constants/ThemeSetting";

import InsideHeader from "../../containers/Topbar/InsideHeader/index";
import PublicSidebar from "../../containers/PublicSidebar/index";

const { Content, Footer } = Layout;

const dataSource = [
  { name: "Архангай ", phone1: "7033 2886", phone2: "7033 102", phone3: "" },
  {
    name: "Баян-Өлгий",
    phone1: "7042 2705",
    phone2: "7019 4050",
    phone3: "9444 2902",
  },
  {
    name: "Баянхонгор ",
    phone1: "7044 4010",
    phone2: "7044 102",
    phone3: "7044 2122",
  },
  { name: "Булган ", phone1: "7034 3389", phone2: "7034 102", phone3: "" },
  {
    name: "Говь-Алтай",
    phone1: "7048 3371",
    phone2: "7048 102",
    phone3: "9829 9111",
  },
  { name: "Говьсүмбэр ", phone1: "7054 3661", phone2: "7054 102", phone3: "" },
  {
    name: "Дархан-Уул",
    phone1: "7037 0000",
    phone2: "7037 102",
    phone3: "7037 8737",
  },
  { name: "Дорноговь", phone1: "7052 2738", phone2: "7052 3239", phone3: "" },
  {
    name: "Дорнод",
    phone1: "7058 4455",
    phone2: "7058 102",
    phone3: "7058 4458",
  },
  { name: "Дундговь ", phone1: "7059 3971", phone2: "7059 102", phone3: "" },
  {
    name: "Завхан ",
    phone1: "7046 2202",
    phone2: "7046 102",
    phone3: "8966 3010",
  },
  { name: "Орхон", phone1: "7035 0136", phone2: "7035 7224", phone3: "" },
  {
    name: "Өвөрхангай",
    phone1: "7046 2202",
    phone2: "7032 102",
    phone3: "7032 2108",
  },
  {
    name: "Өмнөговь ",
    phone1: "7035 0136",
    phone2: "7053 102",
    phone3: "7053 2005",
  },
  { name: "Сүхбаатар ", phone1: "7051 8979", phone2: "7051 8102", phone3: "" },
  { name: "Сэлэнгэ", phone1: "7036 2269", phone2: "7036 3672", phone3: "" },
  { name: "Төв", phone1: "7027 2484", phone2: "7027 102", phone3: "" },
  { name: "Увс", phone1: "7045 2424", phone2: "7045 102", phone3: "" },
  { name: "Ховд ", phone1: "7043 2017", phone2: "7043 102", phone3: "" },
  {
    name: "Хөвсгөл ",
    phone1: "7038 2121",
    phone2: "7038 102",
    phone3: "8899 9361",
  },
  {
    name: "Хэнтий ",
    phone1: "7056 3533",
    phone2: "7056 2213",
    phone3: "8917 1776",
  },
  {
    name: "Гэр бүлийн хүчирхийллийн дуудлага, мэдээлэл хүлээн авах “107” тусгай дугаар",
    phone1: "107",
    phone2: "",
    phone3: "",
  },
  {
    name: "Хүүхдийн тусламжийн утас “108”",
    phone1: "108",
    phone2: "",
    phone3: "",
  },
  {
    name: "Баянгол ",
    phone1: "7011 8289",
    phone2: "9494 5409, 9494 5406",
    phone3: "",
  },
  {
    name: "Баянзүрх ",
    phone1: "7000 5494",
    phone2: "9494 5412, 9416 4001",
    phone3: "",
  },
  { name: "Багануур ", phone1: "7021 2288", phone2: "9494 5443", phone3: "" },
  { name: "Багахангай", phone1: "7049 1049", phone2: "", phone3: "" },
  { name: "Налайх ", phone1: "7023 2108", phone2: "", phone3: "" },
  { name: "Чингэлтэй", phone1: "9982 8545", phone2: "9494 5426", phone3: "" },
  { name: "Сүхбаатар ", phone1: "7016 7346", phone2: "9494 5417", phone3: "" },
  {
    name: "Сонгинохайрхан ",
    phone1: "7017 0027",
    phone2: "9494 5418, 9494 5489",
    phone3: "",
  },
  {
    name: "Хан-Уул ",
    phone1: "7777 7373",
    phone2: "9494 5423, 9494 5425",
    phone3: "",
  },
  {
    name: "Гэмтэл согог судлалын үндэсний төв ",
    phone1: "7011 8289",
    phone2: "102, 107 ",
    phone3: "",
  },
  {
    name: "Cүхбаатар дүүргийн нэгдсэн эмнэлэг ",
    phone1: "9401 6601",
    phone2: "102, 107",
    phone3: "",
  },
  {
    name: "Хүчирхийллийн эсрэг үндэсний төв",
    phone1: "96490505",
    phone2: "",
    phone3: "",
  },
  {
    name: "“Үжин“ төв хүүхэд хамгааллын төв",
    phone1: "8668 7373",
    phone2: "",
    phone3: "",
  },
  {
    name: "“Ид-шидийн орон” хүүхэд хамгаалал, хөгжлийн төв",
    phone1: "70006996",
    phone2: "",
    phone3: "",
  },
  {
    name: "Гүнж төв",
    phone1: "70105105",
    phone2: "",
    phone3: "",
  },
  {
    name: "Хөөрхөн Зүрх ТББ",
    phone1: "70159688",
    phone2: "",
    phone3: "",
  },
];

const columns = [
  {
    title: "Орон нутаг",
    dataIndex: "name",
    key: "name",
  },
  {
    title: "Гэр бүл хүүхэд залуучуудын газар",
    dataIndex: "phone1",
    key: "phone1",
  },
  {
    title: "Цагдаагийн газар",
    dataIndex: "phone2",
    key: "phone2",
  },
  {
    title: "Нэг цэгийн үйлчилгээ, хамгаалах байр",
    dataIndex: "phone3",
    key: "phone3",
  },
];

const Main = (props) => {
  var settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrow: false,
  };

  const { width, navStyle } = useSelector(({ settings }) => settings);

  const getSidebar = (navStyle, width) => {
    if (width < TAB_SIZE) {
      return <PublicSidebar />;
    }
    switch (navStyle) {
      case NAV_STYLE_FIXED:
        return <PublicSidebar />;
      case NAV_STYLE_DRAWER:
        return <PublicSidebar />;
      case NAV_STYLE_MINI_SIDEBAR:
        return <PublicSidebar />;
      case NAV_STYLE_NO_HEADER_MINI_SIDEBAR:
        return <PublicSidebar />;
      case NAV_STYLE_NO_HEADER_EXPANDED_SIDEBAR:
        return <PublicSidebar />;
      default:
        return null;
    }
  };

  const onFinish = (values) => {
    console.log(values);
  };

  return (
    <Layout className="gx-app-layout">
      {getSidebar(navStyle, width)}
      <Layout>
        <InsideHeader />
        <Content className="gx-layout-content gx-container-wrap">
          {props.location.pathname === "/home" && (
            <Slider {...settings}>
              <div className="slick-slide">
                <img
                  className="slick-slide-image"
                  height="500"
                  src={`https://file.etuslamj.mn/public-picture/bg_slider_1.jpg`}
                />
                <h2 className="slick-slide-label">Хамтдаа хамгаалъя</h2>
                <h3 className="slick-slide-detail">
                  Та etuslamj.mn-д хандсанаар гэр бүлийн хүчирхийллийн талаарх
                  мэдээлэл, хууль эрх зүйн болон сэтгэлзүйн зөвлөгөө авах
                  боломжтой
                </h3>
              </div>
              {/* <div className="slick-slide">
              <img
                className="slick-slide-image"
                height="600"
                src={`https://file.etuslamj.mn/home/slide/slider2.jpg`}
              />
              <h2 className="slick-slide-label">
                Гэр бүлийн хүчирхийлэл гэж юу вэ?
              </h2>
              <h3 className="slick-slide-detail">
                Та etuslamj.mn-д хандсанаар гэр бүлийн хүчирхийллийн талаарх
                цогц мэдээллийг авахын зэрэгцээ хуульзүй, сэтгэлзүйн зөвлөгөө,
                тусламж авах боломжтой.
              </h3>
            </div>
            <div className="slick-slide">
              <img
                className="slick-slide-image"
                height="600"
                src={`https://file.etuslamj.mn/home/slide/slider3.jpg`}
              />
              <h2 className="slick-slide-label">
                Гэр бүлийн хүчирхийлэл гэж юу вэ?
              </h2>
              <h3 className="slick-slide-detail">
                Та etuslamj.mn-д хандсанаар гэр бүлийн хүчирхийллийн талаарх
                цогц мэдээллийг авахын зэрэгцээ хуульзүй, сэтгэлзүйн зөвлөгөө,
                тусламж авах боломжтой.
              </h3>
            </div> */}
            </Slider>
          )}
          <div className="gx-main-content-wrapper">
            <Switch>
              <Route path={`${props.match.url}`} component={Dashboard} />
              <Route path={`${props.match.url}/widgets`} component={Widgets} />
              <Route path={`${props.match.url}/metrics`} component={Metrics} />
              <Route path={`${props.match.url}/layouts`} component={Layouts} />
              <Route
                path={`${props.match.url}/algolia`}
                component={asyncComponent(() => import("../algolia"))}
              />
            </Switch>
          </div>

          {props.location.pathname === "/home" && (
            <div className="ygodoo">
              <Row gutter={20}>
                <Col xl={24} lg={24} md={24} sm={24} xs={24}>
                  <div className="ygodoo-title">
                    <h1
                      className="card-ygodoo-main"
                      style={{ fontWeight: "bold", color: "white" }}
                    >
                      ЯГ ОДОО АЯН
                    </h1>
                  </div>
                </Col>
              </Row>

              <Row justify="center" gutter={50} style={{ padding: "50px" }}>
                <Col xl={8} lg={16} md={24} sm={24} xs={24}>
                  <Row>
                    <Col xl={24} lg={16} md={24} sm={24} xs={24}>
                      <h2
                        style={{ color: "#fff" }}
                        className="h4 gx-text-capitalize gx-mb-0 card-ygodoo-title"
                      >
                        Танилцуулга
                      </h2>

                      <br />

                      <h5 style={{ textAlign: "justify", color: "white" }}>
                        Жендэрт суурилсан хүчирхийлэлтэй тэмцэх 16 хоногийн
                        аяныг “ӨРХ ГЭР, АЖЛЫН БАЙР, ОЛОН НИЙТИЙН ГАЗАРТ ЖЕНДЭРТ
                        СУУРИЛСАН ХҮЧИРХИЙЛЛИЙГ ЯГ ОДОО ЦЭГЛЭЕ!” уриатайгаар
                        дэлхий нийтээр тэмдэглэн хамтдаа хүчирхийллийн эсрэг
                        тэмцэж байна.
                      </h5>
                    </Col>
                    <Col
                      xl={24}
                      lg={16}
                      md={24}
                      sm={24}
                      xs={24}
                      style={{ marginTop: 10 }}
                    >
                      <video width="100%" controls>
                        <source src="https://file.etuslamj.mn/public-picture/prevent/ygodoo/ygodooehlel.mp4" />
                      </video>
                    </Col>
                  </Row>
                </Col>
                <Col xl={8} lg={16} md={24} sm={24} xs={24}>
                  <Row>
                    <Col span={24}>
                      <h2
                        style={{ color: "white" }}
                        className="h4 gx-text-capitalize gx-mb-0 card-ygodoo-title"
                      >
                        Видео
                      </h2>
                      <br />
                      <h5 style={{ textAlign: "justify", color: "white" }}>
                        Гэмт хэргээс урьдчилан сэргийлэх ажлыг зохицуулах зөвлөл
                        Хүчирхийллийн эсрэг ЯГ ОДОО аянд нэгдье
                      </h5>
                    </Col>

                    <Col span={24} style={{ marginTop: 40 }}>
                      <iframe
                        width="100%"
                        height="315"
                        src="https://www.youtube.com/embed/2nxMZqSfDsg"
                        frameBorder="0"
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                        allowFullScreen
                      ></iframe>
                    </Col>
                  </Row>
                </Col>
                <Col xl={8} lg={16} md={24} sm={24} xs={24}>
                  <Row>
                    <Col span={24}>
                      <h2 className="h4 gx-text-capitalize gx-mb-0 card-ygodoo-title">
                        Даван туулагч түүх
                      </h2>
                      <br />
                      <h5 style={{ textAlign: "justify", color: "white" }}>
                        Жендэрт суурилсан хүчирхийллийг даван туулагчид түүхээ
                        хуваалцаж байна... Таны өөрийн болон таньдаг хэн нэгний
                        амьдрал энэ түүхтэй ойролцоо мэт санагдаж байгаа бол Та
                        бүү эмзэглээрэй! Бас бүү буруутгаарай.
                      </h5>
                    </Col>
                    <Col
                      span={24}
                      style={{ marginTop: 20, textAlign: "center" }}
                    >
                      <a
                        href="https://www.flipsnack.com/7C9675BBDC9/-.html"
                        target="_blank"
                      >
                        <img
                          width="240"
                          src="https://file.etuslamj.mn/public-picture/prevent/ygodoonom1.png"
                          alt=""
                        />
                      </a>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </div>
          )}

          {props.location.pathname === "/home" && (
            <Row>
              <Col
                span={24}
                className="card-title"
                style={{ textAlign: "center", margin: "30px 0px" }}
              >
                Хэрсүү гурвалжин
              </Col>

              <Col span={24}>
                <Row justify="center">
                  <Col xl={5} lg={12} md={24} sm={24} xs={24}>
                    <a
                      href="https://file.etuslamj.mn/public-picture/hersuu.pdf"
                      target="_blank"
                    >
                      <img
                        width="400"
                        src="https://file.etuslamj.mn/public-picture/hersuu_nom.png"
                      />
                    </a>
                  </Col>
                  <Col xl={5} lg={12} md={24} sm={24} xs={24}>
                    <img
                      width="400"
                      src="https://file.etuslamj.mn/public-picture/hersuu.jpg"
                    />
                  </Col>
                </Row>
              </Col>
            </Row>
          )}

          <Footer style={{ marginTop: 30 }}>
            <div className="gx-main-content-wrapper">
              <Row gutter={20}>
                <Col xl={8} lg={16} md={24} sm={24} xs={24}>
                  <Row>
                    <Col xl={24} lg={16} md={24} sm={24} xs={24}>
                      <h2 className="h4 gx-text-capitalize gx-mb-0 footer-title">
                        Танд тусалъя
                      </h2>
                      <br />
                      Та өөрт тулгамдаж буй санал хүсэлт, мэдээллээ бичээрэй.
                      Бид танд эргэн хариу өгөх болно. Мөн мессенжер icon дээр
                      дарж Facebook chat-аар дамжуулан холбогдох боломжтой.
                      <Form
                        layout="vertical"
                        onFinish={onFinish}
                        style={{ marginTop: 30 }}
                      >
                        <Form.Item
                          name={["user", "email"]}
                          label="И-мэйл"
                          rules={[
                            {
                              type: "email",
                            },
                          ]}
                        >
                          <Input />
                        </Form.Item>
                        <Form.Item
                          name={["user", "introduction"]}
                          label="Санал хүсэлт"
                        >
                          <Input.TextArea rows={3} />
                        </Form.Item>
                        <Form.Item>
                          <Button type="primary" htmlType="submit">
                            Илгээх
                          </Button>
                        </Form.Item>
                      </Form>
                    </Col>

                    <Col xl={24} lg={16} md={24} sm={24} xs={24}>
                      <h2 className="h4 gx-text-capitalize gx-mb-0 footer-title">
                        Холбоо барих
                      </h2>
                    </Col>

                    <Col
                      xl={24}
                      lg={16}
                      md={24}
                      sm={24}
                      xs={24}
                      style={{ marginTop: 20 }}
                    >
                      <div>Утас: 51-231415</div>
                      <div>Имэйл: info@etuslamj.mn</div>
                      <Row>
                        <Col span={24}>
                          <a
                            href="https://www.facebook.com/ETuslamjmn-114559800408509/"
                            target="_blank"
                            class="fa fa-facebook"
                          ></a>
                          <a
                            href="https://www.youtube.com/channel/UCIeJ5SlOHIxAaMeFb6hPC4g"
                            target="_blank"
                            class="fa fa-youtube"
                          ></a>
                        </Col>
                      </Row>
                      <div></div>
                      <div></div>
                      <div></div>
                      2020. Хөдөлмөр, нийгмийн хамгааллын яам
                    </Col>
                  </Row>
                </Col>

                <Col xl={16} lg={16} md={24} sm={24} xs={24}>
                  <Row>
                    <Col xl={24} lg={16} md={24} sm={24} xs={24}>
                      <h2 className="h4 gx-text-capitalize gx-mb-0 footer-title">
                        Гэр бүлийн хүчирхийллийн хохирогчдод үйлчилгээ үзүүлдэг
                        төр, төрийн бус байгууллагуудын утасны жагсаалт
                      </h2>
                    </Col>
                    <Col
                      xl={24}
                      lg={16}
                      md={24}
                      sm={24}
                      xs={24}
                      style={{ marginTop: 20 }}
                    >
                      <Table
                        dataSource={dataSource}
                        columns={columns}
                        bordered
                        pagination={false}
                        size="small"
                      />
                    </Col>
                  </Row>
                </Col>

                {/* <Col xl={8} lg={16} md={24} sm={24} xs={24}>
                  <Row>
                    <Col xl={24} lg={16} md={24} sm={24} xs={24}>
                      <h2 className="h4 gx-text-capitalize gx-mb-0 footer-title">
                        Хүчирхийллийн хохирогчдод үйлчилгээ үзүүлдэг ТББ
                      </h2>
                    </Col>
                    <Col
                      xl={24}
                      lg={16}
                      md={24}
                      sm={24}
                      xs={24}
                      style={{ marginTop: 20 }}
                    >
                      <Table
                        bordered
                        dataSource={data2}
                        columns={columns}
                        pagination={false}
                        size="small"
                      />
                    </Col>
                  </Row>
                </Col> */}
              </Row>
            </div>
          </Footer>

          <div id="mySidenav" class="sidenav">
            {/* <a href="#" id="about">
              Түгээмэл асуулт хариулт
            </a> */}
            <a href="/home/prevent/test" id="blog">
              Сэтгэлзүйн сорил
            </a>
            <a href="/home/security/legal-advice" id="projects">
              Хууль зүйн цахим зөвлөгөө
            </a>
            <a href="/home/security/mental-advice" id="contact">
              Сэтгэлзүйн цахим зөвлөгөө
            </a>
            <a href="/home/prevent/wrong" id="family">
              Гэр бүлийн хүчирхийллийн талаарх алдаатай ойлголтууд
            </a>
          </div>
        </Content>
      </Layout>
    </Layout>
  );
};
export default Main;
