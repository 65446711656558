import React from "react";
import { useDispatch } from "react-redux";
import { Avatar, Popover } from "antd";
import { userSignOut } from "../../appRedux/actions/Auth";
import { Link } from "react-router-dom";

const UserProfile = (props) => {
  const dispatch = useDispatch();

  const userMenuOptions = (
    <ul className="gx-user-popover">
      <li>
        <Link to={"/profile"} style={{ color: "#545454" }}>
          Хувийн мэдээлэл
        </Link>
      </li>
      <li onClick={() => dispatch(userSignOut())}>Системээс гарах</li>
    </ul>
  );

  let username = "Хэрэглэгч";

  if (props.user) {
    username = props.user.lastname + " " + props.user.firstname;
  }

  return (
    <div className="gx-flex-row gx-align-items-center gx-mb-4 gx-avatar-row">
      <Popover
        placement="bottomRight"
        content={userMenuOptions}
        trigger="click"
      >
        <Avatar
          src="https://via.placeholder.com/150x150"
          className="gx-size-40 gx-pointer gx-mr-3"
          alt=""
        />
        <span className="gx-avatar-name">
          {username}
          <i className="icon icon-chevron-down gx-fs-xxs gx-ml-2" />
          <p>asdasd</p>
        </span>
      </Popover>
    </div>
  );
};

export default UserProfile;
