import { USER_LIST_SUCCESS, USER_CHANGE_PASSWORD_SUCCESS, USER_APPROVE_SUCCESS } from "../../constants/ActionTypes";

const INIT_STATE = {
  userList: [],
  changePassword: null,
  changeStatus: null
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {

    case USER_LIST_SUCCESS: {
      return {
        changePassword: null,
        changeStatus: null,
        userList: action.payload
      }
    }

    case USER_CHANGE_PASSWORD_SUCCESS: {
      return {
        ...state,
        changePassword: action.payload
      }
    }

    case USER_APPROVE_SUCCESS: {
      return {
        ...state,
        changeStatus: action.payload
      }
    }

    default:
      return state;
  }
}
