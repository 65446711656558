// Customizer const
export const TOGGLE_COLLAPSED_NAV = 'TOGGLE_COLLAPSE_MENU';
export const WINDOW_WIDTH = 'WINDOW-WIDTH';
export const SWITCH_LANGUAGE = 'SWITCH-LANGUAGE';

//Contact Module const
export const FETCH_START = 'fetch_start';
export const FETCH_SUCCESS = 'fetch_success';
export const FETCH_ERROR = 'fetch_error';
export const SHOW_MESSAGE = 'SHOW_MESSAGE';
export const HIDE_MESSAGE = 'HIDE_MESSAGE';
export const ON_SHOW_LOADER = 'ON_SHOW_LOADER';
export const ON_HIDE_LOADER = 'ON_HIDE_LOADER';

//Auth const
export const SIGNUP_USER = 'SIGNUP_USER';
export const SIGNUP_USER_SUCCESS = 'SIGNUP_USER_SUCCESS';
export const SIGNIN_GOOGLE_USER = 'SIGNIN_GOOGLE_USER';
export const SIGNIN_GOOGLE_USER_SUCCESS = 'SIGNIN_GOOGLE_USER_SUCCESS';
export const SIGNIN_FACEBOOK_USER = 'SIGNIN_FACEBOOK_USER';
export const SIGNIN_FACEBOOK_USER_SUCCESS = 'SIGNIN_FACEBOOK_USER_SUCCESS';
export const SIGNIN_TWITTER_USER = 'SIGNIN_TWITTER_USER';
export const SIGNIN_TWITTER_USER_SUCCESS = 'SIGNIN_TWITTER_USER_SUCCESS';
export const SIGNIN_GITHUB_USER = 'SIGNIN_GITHUB_USER';
export const SIGNIN_GITHUB_USER_SUCCESS = 'signin_github_user_success';
export const SIGNIN_USER = 'SIGNIN_USER';
export const SIGNIN_USER_SUCCESS = 'SIGNIN_USER_SUCCESS';
export const SIGNOUT_USER = 'SIGNOUT_USER';
export const SIGNOUT_USER_SUCCESS = 'SIGNOUT_USER_SUCCESS';
export const INIT_URL = 'INIT_URL';
export const USER_DATA = 'user_data';
export const USER_TOKEN_SET = 'user_token_set';

//User const
export const USER_LIST = 'USER_LIST';
export const USER_LIST_SUCCESS = 'USER_LIST_SUCCESS';
export const USER_APPROVE = 'USER_APPROVE';
export const USER_APPROVE_SUCCESS = 'USER_APPROVE_SUCCESS';
export const USER_CHANGE_PASSWORD = 'USER_CHANGE_PASSWORD';
export const USER_CHANGE_PASSWORD_SUCCESS = 'user_token_set';

//Crime
export const CREATE_CRIME_ORG = "CREATE_CRIME_ORG";
export const CREATE_CRIME_ORG_SUCCESS = "CREATE_CRIME_ORG_SUCCESS";
export const VIOLATION_LIST = "VIOLATION_LIST";
export const VIOLATION_APPROVE = "VIOLATION_APPROVE";
export const GET_CRIME_BY_ID = "GET_CRIME_BY_ID";
export const GET_CRIME_BY_ID_STARTED = "GET_CRIME_BY_ID_STARTED";
export const GET_VICTIM_BY_RD = "GET_VICTIM_BY_RD";
export const GET_VICTIM_BY_RD_CLEAN = "GET_VICTIM_BY_RD_CLEAN";
export const GET_VICTIMCHILD_BY_RD = "GET_VICTIMCHILD_BY_RD";
export const GET_VICTIMCHILD_BY_RD_CLEAN = "GET_VICTIMCHILD_BY_RD_CLEAN";
export const CREATE_CRIME_SERVICE = "CREATE_CRIME_SERVICE";
//Regions
export const GET_REGIONS = "GET_REGIONS";

//Public
export const GET_DASHBOARD_DATA = "GET_DASHBOARD_DATA";

//Clear Subscription
export const CLEAR_SUBSCRIPTION = "CLEAR_SUBSCRIPTION";