import { Button, Form, Input, message, Modal, Typography } from "antd";
import InfoView from "components/InfoView";
import JWTdecode from "jwt-decode";
import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import IntlMessages from "util/IntlMessages";
import { USER_TOKEN_SET } from "../constants/ActionTypes";
import { userSignIn } from "../util/Api";

const SignIn = (props) => {
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();

  const isMountedRef = useRef(null);
  const token = useSelector(({ auth }) => auth.token);

  const onFinish = (values) => {
    setLoading(true);
    userSignIn(values, onSuccess, onFailed);
  };

  const onSuccess = (res) => {
    if (isMountedRef.current) {
      const user = JWTdecode(res.token);
      if (user.type !== "98") {
        localStorage.setItem("token", JSON.stringify(res.token));
        dispatch({ type: USER_TOKEN_SET, payload: res.token });
        props.history.push("/dashboard");
      } else {
        message.warn("Системд нэвтрэх эрхгүй хэрэглэгч байна.");
        setLoading(false);
      }
    }
  };

  function info() {
    Modal.info({
      title: (
        <Typography.Paragraph
          style={{
            textAlign: "center",
            fontSize: "18px",
          }}
        >
          Та бидэнтэй{" "}
          <a
            target="_blank"
            href="https://www.facebook.com/groups/734363657464541/"
          >
            {" "}
            ЭНЭ FACEBOOK ГРУПП
          </a>{" "}
          болон
          <a
            target="_blank"
            href="https://www.facebook.com/ETuslamjmn-114559800408509/"
          >
            {" "}
            ЭНЭ ХУУДСААР
          </a>{" "}
          холбогдон нууц үгээ сэргээлгэнэ үү.
        </Typography.Paragraph>
      ),
      onOk() {},
    });
  }

  const onFailed = (err) => {
    setLoading(false);
    message.error(err);
  };

  useEffect(() => {
    isMountedRef.current = true;
    if (token) {
      props.history.push("/");
    }
    return () => (isMountedRef.current = false);
  }, [token, props.history]);

  return (
    <div className="gx-app-login-wrap">
      <div className="gx-app-login-container">
        <div className="gx-app-login-main-content">
          <div className="gx-app-logo-content">
            <div className="gx-app-logo-content-bg">
              <img
                src={require("assets/images/login.jpg")}
                alt="Neature"
                style={{ objectFit: "cover" }}
              />
            </div>
            <div className="gx-app-logo-wid" style={{ margin: "auto" }}>
              <h1>etuslamj.mn</h1>
              <p>
                <IntlMessages id="app.userAuth.getAccount" />
              </p>
            </div>
          </div>
          <div className="gx-app-login-content">
            <Form
              initialValues={{ remember: true }}
              name="basic"
              onFinish={onFinish}
              className="gx-signin-form gx-form-row0"
            >
              <Form.Item
                rules={[
                  {
                    required: true,
                    message: "E-mail хаягыг оруулах шаардлагатай",
                  },
                ]}
                name="email"
              >
                <Input placeholder="Email" />
              </Form.Item>
              <Form.Item
                rules={[
                  {
                    required: true,
                    message: "Нууц үгээ оруулах шаардлагатай!",
                  },
                ]}
                name="password"
              >
                <Input type="password" placeholder="Password" />
              </Form.Item>

              <div style={{ marginBottom: "15px" }}>
                <Button
                  loading={loading}
                  type="primary"
                  className="gx-mb-0"
                  htmlType="submit"
                >
                  <IntlMessages id="app.userAuth.signIn" />
                </Button>
                <span>
                  <IntlMessages id="app.userAuth.or" />
                </span>{" "}
                <Link to="/signup">
                  <IntlMessages id="app.userAuth.signUp" />
                </Link>
              </div>

              <Button
                type="link"
                className="gx-login-form-forgot"
                onClick={info}
                to="/custom-views/user-auth/forgot-password"
              >
                Нууц үгээ мартсан
              </Button>
            </Form>
          </div>
          <InfoView />
        </div>
      </div>
      <div className="login-image">
        <img
          alt="example"
          style={{
            height: "49.49px",
            marginRight: "75px",
          }}
          src={require("assets/images/logo.png")}
        />
        <img
          alt="example"
          style={{
            height: "71.58px",
            marginRight: "75px",
          }}
          src={require("assets/images/logo2.png")}
        />
        <img
          alt="example"
          style={{
            height: "71.58px",
          }}
          src={require("assets/images/sdc.png")}
        />
      </div>
    </div>
  );
};

export default SignIn;
