import { 
  CREATE_CRIME_ORG, 
  CREATE_CRIME_ORG_SUCCESS, 
  VIOLATION_LIST, 
  VIOLATION_APPROVE,
  GET_VICTIM_BY_RD, 
  GET_VICTIM_BY_RD_CLEAN,
  GET_VICTIMCHILD_BY_RD,
  GET_VICTIMCHILD_BY_RD_CLEAN,
  GET_CRIME_BY_ID,
  GET_CRIME_BY_ID_STARTED,
  CREATE_CRIME_SERVICE,
} from "../../constants/ActionTypes";


const INIT_STATE = {
  userList: [],
  violationList: [],
  newCrime: null,
  approveViolation: null,
  victimByRg: null,
  victimChildByRg: null,
  crimeView: null,
  newCrimeSerivce: null,
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case CREATE_CRIME_ORG: {
      return {
        ...state,
        violationList: null
      }
    }

    case CREATE_CRIME_ORG_SUCCESS: {
      return {
        ...state,
        newCrime: action.payload,
        victimByRg: null,
      }
    }

    case VIOLATION_LIST: {
      return {
        ...state,
        violationList: action.payload,
      }
    }

    case VIOLATION_APPROVE: {
      return {
        ...state,
        approveViolation: 'approveViolation',
      }
    }

    case GET_VICTIM_BY_RD: {
      return {
        ...state,
        victimByRg: action.payload
      }
    }

    case GET_VICTIM_BY_RD_CLEAN: {
      return {
        ...state,
        victimByRg: null
      }
    }

    case GET_VICTIMCHILD_BY_RD: {
      return {
        ...state,
        victimChildByRg: action.payload
      }
    }

    case GET_VICTIMCHILD_BY_RD_CLEAN: {
      return {
        ...state,
        victimChildByRg: null,
      }
    }

    case GET_CRIME_BY_ID: {
      return {
        ...state,
        crimeView: action.payload
      }
    }

    case GET_CRIME_BY_ID_STARTED: {
      return {
        ...state,
        crimeView: null
      }
    }

    case CREATE_CRIME_SERVICE: {
      return {
        ...state,
        newCrimeSerivce: action.payload
      }
    }

    default:
      return state;
  }
}
