import React from "react";
import { Route, Switch } from "react-router-dom";
import PrivateRoute from "util/PrivateRoute";
import asyncComponent from "util/asyncComponent";
import { useSelector } from "react-redux";

const App = ({ match }) => {
  const { currentUser } = useSelector(({ auth }) => ({
    currentUser: auth.currentUser,
  }));

  console.log(currentUser);

  return (
    <div className="gx-main-content-wrapper">
      <Switch>
        <Route
          path={`${match.url}dashboard`}
          component={asyncComponent(() => import("./Dashboard"))}
        />
        {currentUser.type === "99" || currentUser.type === "1" ? (
          <Route
            path={`${match.url}users`}
            component={asyncComponent(() => import("./Users"))}
          />
        ) : null}
        <Route
          path={`${match.url}crime`}
          component={asyncComponent(() => import("./Crime"))}
        />
        <PrivateRoute
          path={`${match.url}crime-main`}
          permissions={["1", "2", "3", "4", "6", "99"]}
          component={asyncComponent(() => import("./Crime/CrimeMain"))}
        />
        <PrivateRoute
          path={`${match.url}child-crime-main`}
          permissions={["1", "2", "3", "4", "6", "99"]}
          component={asyncComponent(() => import("./Crime/CreateChild/List"))}
        />
        <Route
          path={`${match.url}profile`}
          component={asyncComponent(() => import("./Users/views/ProfilePage"))}
        />
        <PrivateRoute
          path={`${match.url}nohtsolbaidal`}
          permissions={["1", "2", "3", "4", "6", "99"]}
          component={asyncComponent(() => import("./Crime/nohtsolbaidal"))}
        />

        <PrivateRoute
          path={`${match.url}service`}
          permissions={["1", "2", "3", "4", "6", "99"]}
          component={asyncComponent(() => import("./Crime/service"))}
        />

        <PrivateRoute
          path={`${match.url}child-service`}
          permissions={["1", "2", "3", "4", "6", "99"]}
          component={asyncComponent(() => import("./Crime/Child/service"))}
        />

        <PrivateRoute
          path={`${match.url}child-nohtsolbaidal`}
          permissions={["1", "2", "3", "4", "6", "99"]}
          component={asyncComponent(() =>
            import("./Crime/Child/nohtsolbaidal")
          )}
        />

        {currentUser.type === "99" || currentUser.type === "1" ? (
          <PrivateRoute
            path={`${match.url}report`}
            permissions={["1", "2", "3", "4", "6", "99"]}
            component={asyncComponent(() => import("./Crime/report"))}
          />
        ) : null}

        {currentUser.type === "99" || currentUser.type === "1" ? (
          <PrivateRoute
            path={`${match.url}child-report`}
            permissions={["1", "2", "3", "4", "6", "99"]}
            component={asyncComponent(() => import("./Crime/child-report"))}
          />
        ) : null}
      </Switch>
    </div>
  );
};

export default App;
