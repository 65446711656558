import JWTdecode from "jwt-decode";
import {INIT_URL, SIGNOUT_USER_SUCCESS, USER_DATA, USER_TOKEN_SET, SIGNUP_USER_SUCCESS} from "../../constants/ActionTypes";

const INIT_STATE = {
  token: JSON.parse(localStorage.getItem('token')),
  currentUser: localStorage.getItem("token") ? JWTdecode(localStorage.getItem("token")) : null,
  initURL: '',
  authUser: JSON.parse(localStorage.getItem('user')),
  newUser: null,
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {


    case INIT_URL: {
      return {...state, initURL: action.payload};
    }

    case SIGNOUT_USER_SUCCESS: {
      return {
        ...state,
        token: null,
        authUser: null,
        currentUser: null,
        initURL: ''
      }
    }

    case SIGNUP_USER_SUCCESS: {
      return {
        ...state,
        newUser: "amjilttai",
      }
    }
    
    case USER_DATA: {
      return {
        ...state,
        authUser: action.payload,
      };
    }

    case USER_TOKEN_SET: {
      return {
        ...state,
        token: action.payload,
        currentUser: action.payload ? JWTdecode(action.payload) : null,
      };
    }

    default:
      return state;
  }
}
